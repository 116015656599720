import React, { Component } from 'react';
//import ReactDOM from 'react-dom'
import './App.css';
//import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { LazyLog } from 'react-lazylog';
import { useMachine } from '@xstate/react';
import { keeFSM } from './keeMachine';
//import EventEmitter from 'react-event-emitter';

// const acEventEmitter = new EventEmitter();

function remoteWhitelisted() { 
   if (window.clientIP === "somejuan")     return true;
//   if (window.clientIP === "98.4.112.204") return true;
   return false;
}

const inTheWild      = !remoteWhitelisted();
const isProd         = true;
const domNode        = document.getElementById('root');

var acTextInContext  = "";
if (isProd) 
     acTextInContext = (!inTheWild) ? "https://ac.ai-integration.biz/AC.log.txt" : "https://ac.ai-integration.biz/loginreq.txt";
else acTextInContext = (!inTheWild) ? "/AC.log.txt"                              : "/loginreq.txt";

function getQueryVariable(variable)
{
        var query = window.location.search.substring(1);
        console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] === variable){return pair[1];}
         }
         return(false);
}

class Button extends React.Component {

  render() {
    const { 
      variant,
      content,
      ...others
    } = this.props;
    
    return (
      <button className={variant} {...others}>
        {content}
      </button>
    )
  }
}

function dcpConnect() {

    const { state: keeFSMState, send: connect } = useMachine( keeFSM, { devTools: false });   

    return keeFSMState.matches('connected');

}  
    
//  <form onSubmit="handleUserTextLine()">
//  <input size="155" maxLength="711" class="sayBox" type="text" onChange="handleUserTextLine()" /><br></br>
//  <Button style={{ position: "relative", left: -5, top: -40 }} title="accept the line of conversation above" content="Say" variant="say" />
//  </form>

function SPAKEE({ view }) {

  switch ( view  ) {
    case 'AC':
      document.title = "Heritage Boot KEE";
      if (!inTheWild) {
        return (
          <div className="App">
            <div style={{ position: "relative", top: -60, left: 150, float: "right" }} >
              <br></br><audio style={{ height: 18, zIndex: 100 }}
                title="Sarabande - Handel 1705" controls source src="https://meansofproduction.biz/pub/HandelSarabande.mp3"
                type="audio/mpeg"> This pane has an audio but your browser does not support the audio element.</audio> &nbsp; &nbsp; &nbsp;
              <div style={{ fontSize: 8, color: "cyan" }}>This pane has a music track, mouseover for title.</div>
            </div>
            <table width="100%" cellPadding="0" cellSpacing="0">
              <tr>
                <td align="left" valign="top" rowSpan="2" width="7%">
                  <Button title="submit a text file" content="&#x54a8;"
                    variant="upload" /><br /><br /><br />
                  <Button title="bound DCP episode" content="&#x518d;&#x89C1;"
                    variant="reset" /><br /><br /><br />
                  <Button title="select haskell view" content=""
                    variant="hs" /><br /><br /><br />
                  <Button title="select lisp view" content=""
                    variant="cl" /><br /><br /><br />
                  <Button title="select prolog view" content=""
                    variant="pl" /><br /><br /><br /><br />
                  <Button title="attach MCP to this device" content=""
                    variant="cm" /><br /><br /><br /><br />
                  <a style={{ zIndex: 11, color: "red", position: "relative", left: 600, top: 90 }}
                    title="Bitlbee per user help context" target="bitlbee" href="/?startPane=irc"> <b>IRC View</b> </a>
                </td><td width="5px">&nbsp;</td>
                <td align="left">
                  <div style={{ height: 500, width: 1300, position: "relative", top: -68 }}>
                    <LazyLog extraLines={1} stream enableSearch url={acTextInContext} caseInsensitive />
                  </div>
                </td></tr>
              <tr><td></td><td id="sayBox" colspan="2" align="left" width="93%">
              <span style={{ position: "relative", top: -30, left: 150, float: "center" }} >Press the red (&#x518d;&#x89C1;) button to connect DCP. </span><br></br>
              </td></tr></table>
          </div>);
      } else
        return (
          <div className="App">
            <table width="100%" cellPadding="0" cellSpacing="0">
              <tr>
                <td align="left" valign="top" rowSpan="2" width="7%">
                  <Button title="submit a text file" content="&#x54a8;"
                    variant="upload" /><br /><br /><br />
                  <Button title="bound DCP episode" content="&#x518d;&#x89C1;"
                    variant="reset" /><br /><br /><br />
                  <Button title="select haskell view" content=""
                    variant="hs" /><br /><br /><br />
                  <Button title="select lisp view" content=""
                    variant="cl" /><br /><br /><br />
                  <Button title="select prolog view" content=""
                    variant="pl" /><br /><br /><br /><br />
                  <Button title="attach MCP to this device" content=""
                    variant="cm" /><br /><br /><br /><br />
                </td><td width="5px">&nbsp;</td>
                <td align="left">
                  <div style={{ height: 500, width: 1300, position: "relative", top: -68 }}>
                    <LazyLog extraLines={1} stream enableSearch url={acTextInContext} caseInsensitive />
                  </div>
                </td></tr>
            </table>
            <div style={{ zIndex: 11, color: "red", position: "relative", left: 100, top: 80 }} >
              <a title="Bitlbee per user help context" target="bitlbee" href="/?startPane=irc"> <b>IRC View</b> </a></div>
          </div>);
        
    case 'irc':
      document.title = "DS KEE IRC";
      if (!isProd)
        return (
          <div className="App">
            <table width="100%" cellPadding="0" cellSpacing="0">
              <tr>
                <td align="left" valign="bottom" rowSpan="2" width="7%">
                  {/*  <a style={{ zIndex: 11, color: "red",  position: "relative", left: 40 }} href="/?startPane=AC"> <b>KEE View</b> </a> */}
                </td><td width="5px">&nbsp;</td>
                <td align="left">
                  <iframe title="Bitlbee" style={{ position: "relative", top: -50 }}
                    src="http://192.168.0.9:9090" height="500" width="100%"></iframe>
                </td></tr>
              <tr><td></td><td align="left" width="93%">
              </td></tr>
            </table>
          </div>
        );
      else
        return (
          <div className="App">
            <table width="100%" cellPadding="0" cellSpacing="0">
              <tr>
                <td align="left" valign="bottom" rowSpan="2" width="7%">
                  {/* <a style={{ zIndex: 11, color: "red", position: "relative", left: 40 }} href="/?startPane=AC"> <b>KEE View</b> </a> */}
                </td><td width="5px">&nbsp;</td>
                <td align="left">
                  <iframe title="Bitlbee" style={{ position: "relative", top: -50 }}
                    src="https://irc.sameboat.network" height="500" width="100%"></iframe>
                </td></tr>
              <tr><td></td><td align="left" width="93%">
              </td></tr>
            </table>
          </div>
        );
    default:
      document.title = "Heritage SPA";
      if (!inTheWild)
        return (
          <div className="App">
            <table width="100%" cellPadding="0" cellSpacing="0">
              <tr>
                <td align="left" valign="middle" rowSpan="2" width="7%">
                  <a style={{ zIndex: 11, color: "red", position: "relative", left: 40, top: 250 }}
                    title="About the Domain Control Program" href="https://devops1.sameboat.network/About%20DCP"> <b>About</b> </a>
                </td><td width="5px">&nbsp;</td>
                <td align="left">
                  <div style={{ height: 500, width: "100%", position: "relative", top: -50 }}>
                    <div class="acTranscript" style={{ height: 200, width: 200, margin: "auto", color: "purple" }}>
                      <center>
                        <span class="toplegend"><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                          Domain Space<br></br>Knowledge Engineering</span>
                      </center>
                    </div>
                    <center><blockquote><span class="legend" >
                       <a title="Browser version of the full KEE (KEE Panes are also linked in NLP)" href="/?startPane=AC">[ KEE ] &nbsp; / &nbsp;  &nbsp;
                       <a title="Bitlbee&mdash;Help" target="bitlbee" href="/?startPane=irc">[ NLP ]</a>
                      </a><br></br><br></br><br></br><br></br>
                      <a style={{ fontSize: 8 }} href="https://en.wikipedia.org/wiki/Symbolic_artificial_intelligence"><b>GOFAI</b></a></span></blockquote></center>
                  </div>
                </td></tr>
              <tr><td></td><td align="left" width="93%"></td></tr>
            </table><a href="https://praxis.meansofproduction.biz">
            <img width="150" style={{ zIndex: 100, position: "absolute", bottom: 10, right: 10 }}
              align="right" src="https://juan.ai-integration.biz/xasppage/xasppage.pl?XASPPAGE_STYLE=0&P=BOOTKEE"></img></a>
          </div>
        ); else
        return (
          <div className="App">
            <table width="100%" cellPadding="0" cellSpacing="0">
              <tr>
                <td align="left" valign="middle" rowSpan="2" width="7%">
                  <a style={{ zIndex: 11, color: "red", position: "relative", left: 40, top: 250 }}
                    title="About the Domain Control Program" href="https://devops1.sameboat.network/About%20DCP"> <b>About</b> </a>
                </td><td width="5px">&nbsp;</td>
                <td align="left">
                  <div style={{ height: 500, width: "100%", position: "relative", top: -50 }}>
                    <div class="acTranscript" style={{ height: 200, width: 200, margin: "auto", color: "purple" }}>
                      <center>
                        <span class="toplegend"><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                          Domain Space<br></br> Knowledge Engineering</span>
                      </center>
                    </div>
                    <center><blockquote><span class="legend" >
                      <a title="Browser version of the full KEE (KEE Panes are also linked in NLP)"
                        href="/?startPane=AC">[ KEE ]</a>  &nbsp; / &nbsp;
                        <a title="Bitlbee&mdash;Help" target="bitlbee" href="/?startPane=irc">[ NLP ]</a> 
                      <br></br><br></br><br></br><br></br><br></br><br></br>
                      <a style={{ fontSize: 6, color: "gainsboro" }} href="https://en.wikipedia.org/wiki/Symbolic_artificial_intelligence"><b>GOFAI</b></a></span>
                    </blockquote> </center>
                  </div>
                </td></tr>
              <tr><td></td><td align="left" width="93%"></td></tr>
            </table><a href="https://praxis.meansofproduction.biz">
            <img width="150" style={{ position: "absolute", bottom: 10, right: 10 }}
              align="right" src="https://juan.ai-integration.biz/xasppage/xasppage.pl?XASPPAGE_STYLE=0&P=BOOTKEE"></img></a>
          </div>
        );
      }
  };

function handleUserTextLine(event) {
    event.preventDefault();
    // Is this the best way to get the textbox value?
    // this.props.onSearchTermSubmit(event.target[0].value);
    
    // Using refs:
    // this.props.onSearchTermSubmit(this.textInput.value);
    
    this.props.onUserTextlineSubmit();
  }

export default function App() {

    var pane = getQueryVariable('startPane');

    return (
      <>
        <SPAKEE view={pane} />
      </>
    );
  }
